<template>
  <div class="Login">
    <div class="Login__box">
      <h2 class="Login__h2">
        Yayにログイン
      </h2>

      <p class="Login_error">
        {{ Validation.loginReult }}
      </p>

      <form class="Login__form" @submit.prevent="onSubmit">
        <input
          ref="focus_email"
          v-model="user.email"
          type="text"
          class="Input"
          placeholder="メールアドレス"
        />
        <input
          ref="focus_password"
          v-model="user.password"
          type="password"
          class="Input"
          placeholder="パスワード"
        />

        <p class="agreement_attention_confirmation">
          当サービスの<a href="/terms" target="_blank">利用規約</a
          >、個人情報取り扱いについての<a href="/privacy" target="_blank">
            プライバシーポリシー</a
          >について同意の上で、 ログインしてください。
        </p>

        <v-btn
          class="Button"
          type="submit"
          color="red darken-4"
          :loading="loading"
          :disabled="loading"
          @click="checkFrom"
        >
          同意してログイン
          <v-icon right>
            mdi-login
          </v-icon>
        </v-btn>
      </form>

      <p class="Strike">
        <span>または</span>
      </p>
      <a
        class="Login__form__forgot_password_link"
        href="https://yay.space/forgot-password"
        target="_blank"
        rel="noopener noreferrer"
        >パスワードを忘れてしまった場合はこちら
      </a>
      <p class="Login__p">
        アカウントをお持ちでない方はトップページよりアプリをインストール後、アプリより作成してください
      </p>
    </div>
  </div>
</template>

<script>
import ga from "@/plugins/ga";

export default {
  data: function() {
    return {
      user: {
        Email: false,
        Password: false,
      },
      Validation: {
        loginReult: "",
      },
      loading: false,
    };
  },
  methods: {
    checkFrom: function(event) {
      this.loading = true;
      ga.loginPage.login(this.$ga);

      // 初期化
      this.Email = false;
      this.Password = false;
      this.Validation.loginReult = "";

      // フォームチェック
      if (!this.user.email && !this.user.password) {
        this.Validation.loginReult =
          "メールアドレスとパスワードを記入してください";
        this.$refs.focus_email.focus();
      } else if (!this.user.email) {
        this.Validation.loginReult = "メールアドレスを記入してください";
        this.$refs.focus_email.focus();
      } else if (!this.validEmail(this.user.email)) {
        this.Validation.loginReult = "正しいメールアドレスを記入してください";
        this.$refs.focus_email.focus();
      } else if (!this.user.password) {
        this.Validation.loginReult = "パスワードを記入してください";
        this.$refs.focus_password.focus();
      } else {
        this.Email = true;
        this.Password = true;
      }
      // ログイン
      if (this.Email && this.Password) {
        const params = {
          email: this.user.email,
          password: this.user.password,
          api_key:
            "e9f1ae4c4470f29a92c0168dc42b13637cc332692103f23e626bc2b016f66603",
          uuid: "8NRAwXUUVW5LdGy8",
        };

        var Promise1 = new Promise((resolve) => {
          this.axios
            .post(
              "https://cors.bexix.net/https://api.yay.space/v2/users/login_with_email",
              params
            )
            .then((response) => {
              // データの保存
              this.$store.dispatch("auth", {
                userId: response.data.user_id,
                userToken: response.data.access_token,
              });
              this.$router.push({
                path: "/profile",
                query: { id: [response.data.user_id] },
              });
            })
            .catch((error) => {
              if (error.response.data.message === "password is invalid") {
                this.Validation.loginReult =
                  "メールアドレスまたはパスワードが違います";
              } else {
                this.Validation.loginReult =
                  "原因不明のエラーが発生したため、しばらく経ってからやり直してください。";
              }
              resolve(error.response.data);
            });
        });
        Promise1.then(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
      event.preventDefault();
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style>
.Login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em;
}
.Login__box {
  background-color: #fffffb;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
  border-radius: 10px;
  width: 540px;
  padding: 0 6em;
}
.Login__h2 {
  font-size: 23.83px;
  color: #1e2229;
  letter-spacing: 0.55px;
  text-align: center;
  margin-bottom: 1em;
  padding: 1em 0;
}
.Login_error {
  color: #d93025;
  font-size: 13px;
  text-align: center;
}
.Input {
  background: #fafafa;
  border: none;
  border-bottom: 2px solid #e9e9e9;
  color: #666;
  font-size: 1em;
  height: 50px;
  width: 100%;
  margin-bottom: 1.5em;
}

.agreement_attention_confirmation {
  font-size: 0.85em;
}

.Login__form .Button {
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  text-align: center;
  width: 100%;
}
.Strike {
  display: flex;
  align-items: center;
  /* 垂直中心 */
  justify-content: center;
  /* 水平中心 */
  padding: 1.5em 0;
  margin-bottom: 0 !important;
}
.Strike:before,
.Strike:after {
  border-top: 1px solid;
  content: "";
  flex-grow: 1;
}
.Strike:before {
  margin-right: 1em;
  /* 文字の右隣 */
}
.Strike:after {
  margin-left: 1em;
  /* 文字の左隣 */
}
.Strike span {
  font-size: 15.05px;
  color: #282c33;
  letter-spacing: 0.35px;
}

.Login__form__forgot_password_link {
  display: block;
  color: #00bf9e;
  text-decoration: underline;
  text-align: center;
  font-size: 0.85em;
  padding-bottom: 15px;
}

.Login__p {
  text-align: center;
  padding-bottom: 15px;
  font-size: 0.85em;
}
</style>
